import OriginalsTrack from "./OriginalsTrack"

export default class CollectionCard {
	constructor (trackId, cardId) {
		this.trackId = parseInt(trackId)
		this.id = parseInt(cardId)
		this.trackKey = OriginalsTrack.getTrackKeyByTrackId(trackId)
		this.filename = `/tracks/${this.trackId}/${this.getFilenameCardId()}.jpg`
	}

	getFilenameCardId () {
		if (isNaN(this.trackKey * (this.id + 5))) {
			console.log(this.trackKey, this.id)
		}
		return this.trackKey * (this.id + 5)
	}
	
	getId () {
		return this.id
	}
	
	getTrackId () {
		return this.trackId
	}
	
	getFilename () {
		return this.filename
	}
	
	getSmallFilename () {
		return `/tracks/${this.trackId}/small/${this.getFilenameCardId()}.jpg`
	}

	is (otherCard) {
		return this.trackId === otherCard.trackId && this.id === otherCard.id
	}



	/*** I/O ***/

	toStringData () {
		return `${this.trackId}/${this.id}`
	}

	static fromStringData (string) {
		let [ trackId, id ] = string.split('/')
			.map(value => parseInt(value))
		return new CollectionCard(trackId, id)
	}
}