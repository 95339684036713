// Debug.
export const TESTING_NEW_EXPERIENCE = false
export const SAVE_DURING_TESTING_NEW_EXPERIENCE = false
export const ALLOW_SAVE = true
export const DEBUG_SHORTCUTS = true

export const PACK_COST = 10
export const AMULET_ASSEMBLY_REWARD = 100
export const DUPE_CARD_REWARD = 2
export const CARDS_IN_PACK = 3
export const INITIAL_CURRENCY = 100