import Shelf from "./Shelf"

export default class CollectionTrack {
	constructor (originalsTrack, collectionDeck) {
		this.originalsTrack = originalsTrack
		this.collectionDeck = collectionDeck
		this.favoritesShelf = undefined
		this.loadRemainingData()
	}
	
	

	/*** Favorites shelf interface ***/
	
	addFavoritesCardAt (card, index) {
		this.favoritesShelf.addCardAt(card, index)
		this.save()
	}
		
	removeFavoritesCardAt (index) {
		this.favoritesShelf.removeCardAt(index)
		this.save()
	}

	swapFavoritesCards (fromIndex, toIndex) {
		this.favoritesShelf.swapCards(fromIndex, toIndex)
		this.save()
	}
	
	

	/*** OriginalsTrack interface ***/
	
	getId () {
		return this.originalsTrack.id
	}
	
	getBackdrop () {
		return this.originalsTrack.getBackdrop()
	}
	
	getCoverArt () {
		return this.originalsTrack.getCoverArt()
	}
	
	getOriginalsFiles () {
		return this.originalsTrack.getFiles()
	}
	
	getOriginalsAsCards () {
		return this.originalsTrack.getAsCollectionCards()
	}
	
	getBackdropPosition () {
		return this.originalsTrack.backdropPosition
	}
	
	pickRandomCards (quantity, collectionDeck) {
		return this.originalsTrack.pickRandomCards(quantity, collectionDeck)
	}
	
	

	/*** Misc ***/
	
	collectionCards () {
		return this.collectionDeck.cardsByTrackId(this.getId())
	}
	
	totalCollected () {
		return this.collectionCards().length
	}
	
	totalToCollect () {
		return this.originalsTrack.total
	}
	
	isEmpty () {
		return this.totalCollected() === 0
	}
	
	completionRate () {
		return this.totalCollected() / this.totalToCollect()
	}
	
	getCollectedFiles () {
		return this.collectionCards.map(card => card.filename)
	}
	
	hasCard (card) {
		return this.collectionCards().some(c => c.is(card))
	}



	/*** I/O ***/

	save () {
		localStorage.setItem(`ct-${this.getId()}`, JSON.stringify(this.toDict()))
	}

	loadRemainingData () {
		const data = localStorage.getItem(`ct-${this.getId()}`)
		if (data) {
			const parsedData = JSON.parse(data)
			this.favoritesShelf = Shelf.fromDict(parsedData.favoritesShelf)
		} else {
			this.favoritesShelf = new Shelf()
		}
	}

	toDict () {
		return {
			favoritesShelf: this.favoritesShelf.toDict()
		}
	}
}