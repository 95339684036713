import React, { useRef, useState } from 'react'
import Track from '../Track'
import TopShelf from '../TopShelf'
import { AmuletPiece } from '../Amulet'
import './HomeScene.scss'

export default function HomeScene ({ onSceneSelect }) {
	const amuletUrl = "/amulets/17117.jpg"
	
	return <div className="HomeScene">
		<div className="title">Untitled Collector 2</div>
		<div className="options">
			<button className="option" onClick={() => onSceneSelect('amulets')}>
				<img src="home/amulets@2x.png" className="amulets" />
				<div className="label">Amulets</div>
				<div className="hint">Gain coins by assembling amulets</div>
			</button>
			<button className="option" onClick={() => onSceneSelect('tracksOverview')}>
				<img src="home/cards@2x.png" className="cards" />
				<div className="label">Cards</div>
				<div className="hint">Spend coins to open card packs</div>
			</button>
		</div>
	</div>
}