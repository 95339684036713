import CollectionCard from "./CollectionCard"

/**
 * Deck of cards, can contain dupes.
 */
export default class Deck {
	constructor (cards = []) {
		this.cards = cards
	}
	
	total () {
		return this.cards.length
	}
	
	hasCard (card) {
		return this.cards.some(c => c.is(card))
	}
	
	allFilenames () {
		return this.cards.map(card => card.filename)
	}
	
	addCard (card) {
		this.cards.push(card)
	}
	
	addCards (cards = []) {
		cards.forEach(card => this.addCard(card))
		this.sort()
	}
	
	cardsByTrackId (trackId) {
		return this.cards.filter(card => card.trackId === trackId)
	}

	sort () {
		this.cards = this.cards.sort((a, b) => a.id - b.id)
	}

	merge (otherDeck) {
		const newDeck = new Deck()
		newDeck.addCards(this.cards)
		newDeck.addCards(otherDeck.cards)
		return newDeck
	}

	uniqueCards () {
		const filenames = []
		return this.cards.filter(card => {
			if (filenames.includes(card.getFilename())) {
				return false
			} else {
				filenames.push(card.getFilename())
				return true
			}
		})
	}



	/*** I/O ***/

	static fromDict (dict) {
		const cards = dict.cards.map(cardStringData => CollectionCard.fromStringData(cardStringData))
		return new Deck(cards)
	}
}