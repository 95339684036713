import React from 'react'
import { preloadImage } from '../Utils/images'
import { timesArray, shuffledArray } from '../Utils/array'
import './Amulet.scss'

const PIECES_RATIO = 5
const AMULET_PIECES = PIECES_RATIO ** 2

const maxTiltX = 16
const maxTiltY = 6

export default class Amulet extends React.PureComponent {
	constructor (props) {
		super(props)
		this.state = {
			tiltX: 0,
			tiltY: 0,
			tiltAmp: 0,
			preloading: true,
			justAssembled: false
		}
		this.placedPieces = 1
		this.sideStackPositions = shuffledArray(timesArray(AMULET_PIECES))
		this.touching = false
		this.tilting = false
	}
	
	
	
	/*** Tilting ***/
	
	allowHover () {
		return !!this.props.filename
	}
	
	updateTiltAmp () {
		const { tiltAmp } = this.state
		if (this.tilting && tiltAmp < 1) {
			this.setState({ tiltAmp: Math.min(1, tiltAmp + 0.005 + (0.1 - tiltAmp * 0.1)) })
			requestAnimationFrame(() => this.updateTiltAmp())
		} else if (!this.tilting && tiltAmp > 0) {
			this.setState({ tiltAmp: Math.max(0, tiltAmp - (0.01 + tiltAmp * 0.1))  })
			requestAnimationFrame(() => this.updateTiltAmp())
		}
	}
	
	startTilting () {
		if (!this.allowHover()) {
			return
		}
		this.tilting = true
		this.updateTiltAmp()
	}

	tiltInteraction (target, clientX, clientY) {
		if (!this.allowHover()) {
			return
		}
		const rect = target.getBoundingClientRect()
		const centerAlphaX = ((clientX - rect.x) / rect.width - 0.5) * 2
		const centerAlphaY = ((clientY - rect.y) / rect.height - 0.5) * 2
		this.setState({
			tiltX: centerAlphaX,
			tiltY: -centerAlphaY
		})
		if (Math.abs(centerAlphaX) > 1.1 || Math.abs(centerAlphaY) > 1.1) {
			this.onTouchEnd()
		}
	}

	onMouseMove (e) {
		if (e.nativeEvent && e.nativeEvent.webkitForce) {
			return
		}
		this.tiltInteraction(e.target, e.clientX, e.clientY)
	}

	onMouseLeave () {
		this.tilting = false
		this.touching = false
		this.updateTiltAmp()
	}

	onTouchMove (e) {
		e.preventDefault()
		e.stopPropagation()
		this.touching = true
		this.tiltInteraction(e.target, e.touches[0].clientX, e.touches[0].clientY)
	}

	onTouchEnd () {
		if (this.touching) {
			this.onMouseLeave()
		}
	}
	
	
	
	/*** Other ***/

	componentDidMount () {
		preloadImage(this.getUrl())
			.then(() => {
				this.setState({ preloading: false })
				if (this.props.onPreloaded) {
					this.props.onPreloaded()
				}
			})
	}

	getUrl () {
		return this.props.filename
	}

	onClick () {
		if (!this.touching && this.props.onClick && !this.props.disabled) {
			this.props.onClick()
		}
	}

	isAssembling () {
		return this.props.assembling
	}

	getActions () {
		if (!this.props.actions) {
			return []
		}
		let actions = []
		return actions
	}

	renderActionsButtons () {
		return this.getActions().map(action => {
			switch(action) {
				default:
					return null
			}
		})
	}

	onPiecePlaced () {
		this.props.onPiecePlaced()
		this.placedPieces += 1
		if (this.placedPieces >= AMULET_PIECES) {
			this.setState({ justAssembled: true })
			this.props.onFullyAssembled()
		}
	}

	render () {
		if (this.state.preloading) {
			return <div className="Card Amulet preloading">
				<div className="container">
					<div className="surface"></div>
				</div>
			</div>
		}
		
		const { tiltAmp, tiltX, tiltY } = this.state
		const { disabled } = this.props

		const classNames = ['Card Amulet']
		const tilting = this.state.tiltX !== 0 && this.state.tiltY !== 0
		const hasActions = this.getActions().length > 0
		if (tilting) classNames.push('tilting')
		if (this.isAssembling()) classNames.push('assembling')
		if (this.props.new) classNames.push('new')
		if (this.props.inspected) classNames.push('inspected')
		if (this.props.selected) classNames.push('selected')
		if (hasActions) classNames.push('show-actions')
		if (this.props.material) classNames.push('material-' + this.props.material)
		if (disabled) classNames.push('disabled')
		if (this.state.justAssembled) classNames.push('just-assembled')

		const finalTiltX = tiltAmp * tiltX
		const finalTiltY = tiltAmp * tiltY
		const tiltRotateMod = disabled ? 0.5 : 1
		const transformScale = 1 + (!disabled ? 0.15 : 0) * tiltAmp
		const transformRotateY = finalTiltX * maxTiltX * tiltRotateMod
		const transformRotateX = finalTiltY * maxTiltY * tiltRotateMod
		
		return <div
			className={classNames.join(' ')}
			onMouseEnter={this.startTilting.bind(this)}
			onMouseMove={this.onMouseMove.bind(this)}
			onTouchMove={this.onTouchMove.bind(this)}
			onMouseLeave={this.onMouseLeave.bind(this)}
			onTouchEnd={this.onTouchEnd.bind(this)}
			onClick={this.onClick.bind(this)}
			style={{
				'--size': this.props.sizePx ? `${this.props.sizePx}px` : undefined
			}}
		>
			<div
				className="container"
				style={this.isAssembling() ? {} : {
					transform: `scale(${transformScale}) rotateY(${transformRotateY}deg) rotateX(${transformRotateX}deg)`,
					filter: `brightness(${1 + (finalTiltY) / 25})`
				}}
			>
				{ this.isAssembling()
					? <div className="pieces">
						{ timesArray(AMULET_PIECES).map(piece => <AmuletPiece
								key={`piece-${piece}`}
								url={this.getUrl()}
								piece={piece}
								parentSizePx={this.props.sizePx}
								onPlaced={() => this.onPiecePlaced()}
								sideStackOrder={this.sideStackPositions[piece]}
								startPlaced={piece === 3}
							/>)
						}
					</div>
					: <div className="picture" style={{ backgroundImage: `url(${this.getUrl()})` }}></div>
				}
			</div>
			{ hasActions && <div className="actions">
				<div className="container">
					{ this.renderActionsButtons() }
				</div>
			</div> }
		</div>
	}
}



export class AmuletPiece extends React.PureComponent {
	constructor (props) {
		super(props)
		let offset = this.getOffsetForSideStack()
		this.state = {
			placed: this.props.startPlaced,
			dragging: false,
			x: offset.x,
			y: offset.y
		}
		this.onMouseMove = this.onMouseMove.bind(this)
		this.onMouseUp = this.onMouseUp.bind(this)
		this.onTouchMove = this.onTouchMove.bind(this)
		this.onTouchEnd = this.onTouchEnd.bind(this)
		this.onWindowResize = this.onWindowResize.bind(this)
	}

	componentDidMount () {
		window.addEventListener('resize', this.onWindowResize)
	}

	componentWillUnmount () {
		window.removeEventListener('resize', this.onWindowResize)
	}

	onWindowResize () {
		if (this.state.placed) {
			return
		}
		let offset = this.getOffsetForSideStack()
		this.setState({
			x: offset.x,
			y: offset.y
		})
	}

	getOffsetForSideStack () {
		if (this.props.startPlaced) {
			return { x: 0, y: 0 }
		}
		const sizePx = this.props.parentSizePx / PIECES_RATIO
		const targetPosition = {}
		const leftSide = this.props.sideStackOrder < AMULET_PIECES / 2
		targetPosition.x = leftSide
			? -sizePx - 15
			: this.props.parentSizePx + 15
		targetPosition.x -= Math.sin(this.props.sideStackOrder / (AMULET_PIECES / 2) * Math.PI) * (sizePx / 2)
		targetPosition.y = (this.props.sideStackOrder % (AMULET_PIECES / 2)) * (sizePx / 3)
		
		const slicePosition = this.getSlicePosition()
		const localPosition = {
			x: slicePosition.x * sizePx,
			y: slicePosition.y * sizePx
		}
		
		return {
			x: targetPosition.x - localPosition.x,
			y: targetPosition.y - localPosition.y
		}
	}

	getRandomOffset () {
		return {
			x: 0,
			y: 0
		}
	}

	onMouseDown (e) {
		if (this.state.placed) {
			return
		}
		this.setState({ dragging: true })
		this.dragStart = {
			x: e.clientX,
			y: e.clientY
		}
		this.dragInitialOffset = {
			x: this.state.x,
			y: this.state.y
		}
		window.addEventListener('mousemove', this.onMouseMove)
		window.addEventListener('mouseup', this.onMouseUp)
	}

	onMouseMove (e) {
		if (!this.state.dragging) {
			return
		}
		let dragDelta = {
			x: e.clientX - this.dragStart.x,
			y: e.clientY - this.dragStart.y
		}
		this.setState({
			x: this.dragInitialOffset.x + dragDelta.x,
			y: this.dragInitialOffset.y + dragDelta.y
		})
	}

	onMouseUp (e) {
		e.preventDefault()
		this.setState({ dragging: false })
		const threshold = this.props.parentSizePx * 2/100
		if (Math.abs(this.state.x) < threshold && Math.abs(this.state.y) < threshold) {
			this.setState({
				x: 0,
				y: 0,
				placed: true
			})
			this.props.onPlaced()
		}
		window.removeEventListener('mousemove', this.onMouseMove)
		window.removeEventListener('mouseup', this.onMouseUp)
	}

	onTouchStart (e) {
		e.preventDefault()
		if (this.state.placed) {
			return
		}
		this.setState({ dragging: true })
		this.dragStart = {
			x: e.touches[0].pageX,
			y: e.touches[0].pageY
		}
		this.dragInitialOffset = {
			x: this.state.x,
			y: this.state.y
		}
	}

	onTouchMove (e) {
		if (!this.state.dragging) {
			return
		}
		let dragDelta = {
			x: e.changedTouches[0].pageX - this.dragStart.x,
			y: e.changedTouches[0].pageY - this.dragStart.y
		}
		this.setState({
			x: this.dragInitialOffset.x + dragDelta.x,
			y: this.dragInitialOffset.y + dragDelta.y
		})
	}

	onTouchEnd (e) {
		this.onMouseUp(e)
	}

	getSlicePosition () {
		return {
			x: this.props.piece % PIECES_RATIO,
			y: Math.floor(this.props.piece / PIECES_RATIO)
		}
	}

	render () {
		let slicePosition = this.getSlicePosition()
		const backgroundSlicePositions = 100 / (PIECES_RATIO - 1)
		let pieceStyle = {
			left: `${this.state.x}px`,
			top: `${this.state.y}px`
		}
		let imageStyle = {
			backgroundImage: `url("${this.props.url}")`,
			backgroundPosition: `${slicePosition.x * backgroundSlicePositions}% ${slicePosition.y * backgroundSlicePositions}%`
		}
		
		return <div className={`AmuletPiece ${this.state.dragging && 'dragging'} ${this.state.placed && 'placed'}`} style={{ zIndex: this.state.placed ? 0 : this.props.sideStackOrder + 1 }}>
			<div className="piece" style={pieceStyle}
				onMouseDown={this.onMouseDown.bind(this)}
				onTouchStart={this.onTouchStart.bind(this)}
				onTouchMove={this.onTouchMove.bind(this)}
				onTouchEnd={this.onTouchEnd.bind(this)}
			>
				<div className="picture" style={imageStyle}></div>
			</div>
		</div>
	}
}