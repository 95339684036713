import { CARDS_IN_PACK } from '../Data/settings'
import { TRACKS } from '../Data/tracks'
import CollectionCard from '../Models/CollectionCard'
import { rand } from '../Utils/math'
import Deck from './Deck'

export default class OriginalsTrack {
	constructor (trackData) {
		this.id = parseInt(trackData.id)
		this.key = trackData.key
		this.total = parseInt(trackData.total)
		this.backdropPosition = trackData.backdropPosition
	}
	
	getId () {
		return this.id
	}
	
	getFiles () {
		const listing = []
		for (let i = 0; i < this.total; i ++) {
			listing.push(`/tracks/${this.getId()}/${i}.jpg`)
		}
		return listing
	}

	getAsCollectionCards () {
		const listing = []
		for (let i = 0; i < this.total; i ++) {
			listing.push(new CollectionCard(this.id, i))
		}
		return listing
	}
	
	getBackdrop () {
		return `/tracks/${this.getId()}/backdrop.jpg`
	}
	
	getCoverArt () {
		return `/tracks/${this.getId()}/coverart.jpg`
	}
	
	
	
	/*** Card picking ***/
	
	pickRandomCards (quantity = 1, collectionDeck = undefined) {
		let pullDeck = new Deck([])
		for (let i = 0; i < quantity; i ++) {
			pullDeck.addCard(this.pickRandomCard(collectionDeck, pullDeck))
		}
		pullDeck.sort()
		return pullDeck.cards
	}
	
	pickRandomCard (collectionDeck, pullDeck = undefined) {
		const mergedDecks = collectionDeck.merge(pullDeck || new Deck([]))
		const pullCard = () => new CollectionCard(this.getId(), this.pickRandomCardIndexRestrictedByCollection(mergedDecks))
		let card = pullCard()

		// Dupe re-pulls.
		if (collectionDeck.hasCard(card)) {
			if (this.getId() < this.total / 3) {
				// On lower tiers, promote re-pulling for any number.
				card = pullCard()
			} else {
				// On mid to high tiers, promote re-pulling around there.
				const PULL_AROUND_PADDING = 2
				const pullAroundMin = Math.max(0, this.getId() - PULL_AROUND_PADDING)
				const pullAroundMax = Math.max(0, this.getId() + PULL_AROUND_PADDING)
				card = new CollectionCard(this.getId(), rand(pullAroundMin, pullAroundMax))
			}
		}

		// Final extra pull on dupe.
		if (collectionDeck.hasCard(card)) {
			card = pullCard()
		}
		return card
	}
	
	pickRandomCardIndexRestrictedByCollection (collectionDeck) {
		let maxIndex = CARDS_IN_PACK // Purposefully one more than cards in pack.
		collectionDeck.cardsByTrackId(this.id).forEach(card => {
			if (card.id <= this.total / 2) {
				maxIndex += 2
			}
		})
		maxIndex = Math.min(this.total - 1, maxIndex)
		return this.pickRandomCardIndex(maxIndex)
	}
	
	pickRandomCardIndex (lastCardIndex) {
		const tresholds = []
		let tresholdsMax = 0
		for (let i = 0; i <= lastCardIndex; i ++) {
			const threshold = 2 + lastCardIndex - i
			tresholds.push(tresholdsMax + threshold)
			tresholdsMax += threshold
		}
		const ticketNumber = rand(0, tresholdsMax)
		for (let i = 0; i <= lastCardIndex; i ++) {
			if (ticketNumber <= tresholds[i]) {
				return i
			}
		}
		return lastCardIndex
	}
	
	
	
	/*** Static ***/
	
	static loadAll () {
		return TRACKS.map(track_data => new OriginalsTrack(track_data))
	}
	
	static getTrackKeyByTrackId (id) {
		const track = TRACKS.find(track_data => track_data.id === id)
		return track.key
	}
}