import React from 'react'
import './AmuletAssembly.scss'
import Amulet from './Amulet'

export default class AmuletAssembly extends React.PureComponent {
	constructor (props) {
		super(props)
		this.state = {
			preloading: true,
			animating: true,
			fullyAssembled: false,
			sizePx: Math.ceil(Math.min(window.innerWidth, window.innerHeight) * 0.8)
		}
		this.onWindowResize = this.onWindowResize.bind(this)
	}

	componentDidMount () {
		window.addEventListener('resize', this.onWindowResize)
	}

	componentWillUnmount () {
		window.removeEventListener('resize', this.onWindowResize)
	}

	onWindowResize () {
		this.setState({
			sizePx: Math.ceil(Math.min(window.innerWidth, window.innerHeight) * 0.8)
		})
	}
	
	onCardClick (card) {
		if (this.props.onCardClick) {
			this.props.onCardClick(card)
		}
	}

	onCardPreloaded () {
		this.setState({ preloading: false })
		setTimeout(() => {
			this.setState({ animating: false })
			if (this.props.onAnimationEnd) this.props.onAnimationEnd()
		}, 3300)
	}

	onPiecePlaced () {
		this.setState({ piecePlacedPulse: true })
		setTimeout(() => {
			this.setState({ piecePlacedPulse: false })
		}, 1000)
	}

	onFullyAssembled () {
		this.setState({ fullyAssembled: true })
		setTimeout(() => {
			this.props.onAmuletAssembled()
		}, 2800)
	}
	
	render () {
		return <div className={`AmuletAssembly ${this.state.preloading && 'preloading'}  ${this.state.animating && 'animating'} ${this.state.piecePlacedPulse && 'just-placed-pulse'} ${this.state.fullyAssembled && 'fully-assembled'}`}>
			{ this.props.amulet && <Amulet
				filename={this.props.amulet.getFilename()}
				assembling={true}
				onPreloaded={() => this.onCardPreloaded()}
				inspected={true}
				sizePx={this.state.sizePx}
				onPiecePlaced={() => this.onPiecePlaced()}
				onFullyAssembled={() => this.onFullyAssembled()}
			/> }
		</div>
	}
}