import React from 'react'
import Amulet from './Amulet'
import './AmuletsScene.scss'

export default function AmuletsScene ({ amuletTracker }) {
	return <div className={`AmuletsScene`}>
		<div className="scrollable">
			{ amuletTracker.newestFirst().map(amulet => <Amulet key={amulet.getId()} filename={amulet.getFilename()} />) }
		</div>
	</div>
}