import Shelf from "./Shelf"

export default class CollectionShelf extends Shelf {
	addCardAt (card, index) {
		super.addCardAt(card, index)
		this.save()
	}
		
	removeCardAt (index) {
		super.removeCardAt(index)
		this.save()
	}

	swapCards (fromIndex, toIndex) {
		super.swapCards(fromIndex, toIndex)
		this.save()
	}



	/*** I/O ***/
	
	save () {
		localStorage.setItem('cs', JSON.stringify(this.toDict()))
	}
	
	static load () {
		const data = localStorage.getItem('cs')
		if (!data) {
			return new CollectionShelf({})
		}
		return CollectionShelf.fromDict(JSON.parse(data))
	}
}