import React from 'react'
import { AMULET_ASSEMBLY_REWARD, CARDS_IN_PACK, PACK_COST } from '../../Data/settings'
import './Header.scss'

const TRACK_VIEWS = ['row', 'grid']

export default class Header extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			pulseCurrency: false,
			currencyUp: false
		}
		this.currencyPulseTimeout = undefined
		this.onKeyDown = this.onKeyDown.bind(this)
	}

	componentDidMount () {
		document.addEventListener('keydown', this.onKeyDown)
	}

	componentWillUnmount () {
		document.removeEventListener('keydown', this.onKeyDown)
	}

	componentDidUpdate (prevProps) {
		if (prevProps.currency !== this.props.currency) {
			const currencyUp = prevProps.currency < this.props.currency
			this.setState({
				pulseCurrency: true, 
				currencyUp
			})
			
			clearTimeout(this.currencyPulseTimeout)
			this.currencyPulseTimeout = setTimeout(() => this.setState({ pulseCurrency: false }), currencyUp ? 2500 : 500)
		}
	}

	onKeyDown (e) {
		if (this.props.preventKeyCapture || this.props.hidden) {
			return
		}
		if (e.key === 'Escape') {
			e.preventDefault()
			if (this.props.onGoBack) this.props.onGoBack()
		}
		if (e.key === ' ') {
			e.preventDefault()
			if (this.props.onAssembleAmulet) {
				this.props.onAssembleAmulet()
			} else if (this.props.onBuyPack) {
				this.props.onBuyPack()
			}
		}
	}

	render () {
		const { currencyUp, pulseCurrency } = this.state
		const { hidden, currency, onGoBack, onBuyPack, onGoAmulets, onAssembleAmulet, progressValue, progressMax, onShowShelf, onHideShelf, trackView, onTrackViewChange, buyPackNotif, goAmuletsNotif } = this.props

		return <div className={`Header ${hidden && 'hidden'}`}>
			{ onGoBack && <button onClick={onGoBack}>
				<div className="icon icon-go-back"></div>
				<div className="tooltip">
					<div className="caption">Go back</div>
					<div className="key-hint">Esc</div>
				</div>
			</button> }
			{ onBuyPack && <button onClick={onBuyPack}>
				<div className="icon icon-summon-card"></div>
				{ buyPackNotif && <div className="notif"></div> }
				<div className="caption">Buy pack</div>
				<div className="tooltip">
					<div className="caption">Spend { PACK_COST } coins to get a pack of { CARDS_IN_PACK } cards. Track completion increases chances of better cards.</div>
					<div className="key-hint">Spacebar</div>
				</div>
			</button> }
			{ onGoAmulets && <button onClick={onGoAmulets}>
				{ goAmuletsNotif && <div className="notif"></div> }
				<div className="icon icon-amulets"></div>
				<div className="caption">Amulets</div>
			</button> }
			{ onAssembleAmulet && <button onClick={onAssembleAmulet}>
				<div className="notif"></div>
				<div className="icon icon-assemble-amulet"></div>
				<div className="caption">Assemble new amulet</div>
				<div className="tooltip">
					<div className="caption">Get a new amulet to assemble. Gain { AMULET_ASSEMBLY_REWARD } coins when completing one.</div>
					<div className="key-hint">Spacebar</div>
				</div>
			</button> }
			{ currency !== undefined && <div className={`notice ${pulseCurrency && (currencyUp ? 'pulse-grow' : 'pulse-shrink')}`}>
				<div className="icon icon-currency"></div>
				<div className="caption">{ currency }</div>
				<div className="tooltip">Acquire more currency by assembling amulets.</div>
			</div> }
			{ progressValue !== undefined && progressMax !== undefined && <div className="progress-info">
				<div className="progress-bar">
					<div className="progress" style={{ width: `${(progressValue / progressMax) * 100}%`}} ></div>
				</div>
				<div className="caption">
					<span className="value">{ progressValue }</span>
					<span className="max">/ { progressMax }</span>
				</div>
			</div> }
			{ onShowShelf && <button onClick={onShowShelf}>
				<div className="icon icon-amulets"></div>
				<div className="caption">Show shelf</div>
				<div className="tooltip">
					<div className="caption">Allows to see and reorganize this tracks favorites shelf.</div>
				</div>
			</button> }
			{ onHideShelf && <button onClick={onHideShelf}>
				<div className="icon icon-amulets"></div>
				<div className="caption">Hide shelf</div>
			</button> }

			<div className="options">
				{ trackView && TRACK_VIEWS.map(view => <button
						key={view}
						className={`soft can-autohide ${trackView === view && 'selected'}`}
						onClick={() => onTrackViewChange(view)}
					>
						<div className={`icon icon-view-${view}`}></div>
					</button>
				) }
			</div>
		</div>
	}
}