export const TRACKS = [
	{
		id: 1,
		total: 52,
		key: 4109
	},
	{
		id: 2,
		total: 77,
		key: 4112
	},
	{
		id: 3,
		total: 71,
		key: 3977,
		backdropPosition: 'bottom'
	},
	{
		id: 4,
		total: 36,
		key: 624,
	},
	{
		id: 5,
		total: 68,
		key: 4034,
	},
	{
		id: 6,
		total: 62,
		key: 4077,
		backdropPosition: 'bottom'
	},
	{
		id: 7,
		total: 66,
		key: 4175,
	},
	{
		id: 8,
		total: 53,
		key: 3997,
	},
	{
		id: 9,
		total: 49,
		key: 4021,
	},
	{
		id: 10,
		total: 62,
		key: 4251,
	}
]