import { INITIAL_CURRENCY, TESTING_NEW_EXPERIENCE, SAVE_DURING_TESTING_NEW_EXPERIENCE, ALLOW_SAVE } from "../Data/settings"
import CollectionCard from "./CollectionCard"
import Deck from "./Deck"
import CryptoJS from 'crypto-js'

/**
 * Collection deck without dupes, and extra progression values like currency.
 */
export default class CollectionDeck extends Deck {
	constructor (cards, currency = INITIAL_CURRENCY) {
		super(sanitizeCards(cards))
		this.currency = currency
	}
	
	setCurrency (value) {
		this.currency = value
		this.save()
	}
	
	collectCard (card) {
		if (this.hasCard(card)) {
			return false
		} else {
			this.cards.push(card)
			this.save()
			return true
		}
	}
	
	collectCards (cards = []) {
		const collectedCards = cards.reduce((collected, card) => {
			if (this.collectCard(card)) {
				collected.push(card)
			}
			return collected
		}, [])
		this.sort()
		this.save()
		return collectedCards
	}
	
	addCard (card) {
		this.collectCard(card)
	}
	
	addCards (cards = []) {
		this.collectCards(cards)
	}

	getTrackIdsByRecent () {
		const trackIds = []
		this.cards.concat().reverse().forEach(card => {
			if (!trackIds.includes(card.getTrackId())) {
				trackIds.push(card.getTrackId())
			}
		})
		return trackIds
	}



	/*** I/O ***/

	save () {
		if (TESTING_NEW_EXPERIENCE && !SAVE_DURING_TESTING_NEW_EXPERIENCE) return
		if (!ALLOW_SAVE) return
		// localStorage.setItem('cd', JSON.stringify(this.toDict()))
		localStorage.setItem('cd-enc', CryptoJS.AES.encrypt(JSON.stringify(this.toDict()), 'uc2uc2uc2uc2').toString())
	}
	
	static load (collectionTrackManager) {
		const encData = localStorage.getItem('cd-enc')
		// const data = localStorage.getItem('cd')
		if (!encData) {
			return new CollectionDeck([])
		}
		const decryptedData = CryptoJS.AES.decrypt(encData, 'uc2uc2uc2uc2').toString(CryptoJS.enc.Utf8)
		return CollectionDeck.fromDict(JSON.parse(decryptedData), collectionTrackManager)
	}

	toDict () {
		return {
			cards: this.cards.map(card => card.toStringData()),
			currency: this.currency
		}
	}
	
	static fromDict (dict) {
		const cards = dict.cards.map(cardStringData => CollectionCard.fromStringData(cardStringData))
		return new CollectionDeck(cards, parseInt(dict.currency))
	}
}

const sanitizeCards = (cards) => {
	const filenames = []
	return cards.filter(card => {
		if (isNaN(card.getTrackId())) {
			return false
		}
		if (isNaN(card.getId())) {
			return false
		}
		if (filenames.includes(card.getFilename())) {
			return false
		} else {
			filenames.push(card.getFilename())
			return true
		}
	})
}