import CollectionCard from "./CollectionCard"

export const CARDS_IN_SHELF = 10

export default class Shelf {
	constructor (cardsWithPosition = {}) {
		this.cards = {}
		for (let i = 0; i < CARDS_IN_SHELF; i ++) {
			this.cards[i] = cardsWithPosition[i] ? cardsWithPosition[i] : undefined
		}
	}
	
	getCards () {
		return Object.values(this.cards).filter(card => card)
	}
	
	addCardAt (card, index) {
		this.cards[index] = card
	}
		
	removeCardAt (index) {
		this.cards[index] = undefined
	}

	swapCards (fromIndex, toIndex) {
		const from = this.cards[fromIndex]
		const to = this.cards[toIndex]
		this.cards[toIndex] = from
		this.cards[fromIndex] = to
	}

	total () {
		return Object.values(this.cards)
			.filter(card => card)
			.length
	}

	hasCard (otherCard) {
		return this.getCards().some(card => card.is(otherCard))
	}

	hasCards () {
		return this.total() > 0
	}
	
	

	/*** I/O ***/

	toDict () {
		const positions = {}
		Object.keys(this.cards).forEach(index => {
			if (this.cards[index]) positions[index] = this.cards[index].toStringData()
		})
		return {
			cards: positions
		}
	}

	static fromDict (dict) {
		const cards = {}
		Object.keys(dict.cards).forEach(index => {
			cards[parseInt(index)] = CollectionCard.fromStringData(dict.cards[index])
		})
		return new this(cards)
	}
}