import { useState, useEffect } from 'react'
import './App.scss'
import OpenPackScene from './Components/Scenes/OpenPackScene'
import Header from './Components/GUI/Header'
import DropzonesOverlay from './Components/GUI/DropzonesOverlay'
import Track from './Components/Track'
import AmuletAssembly from './Components/AmuletAssembly'
import AmuletsScene from './Components/AmuletsScene'
import HomeScene from './Components/Scenes/HomeScene'
import TracksScene from './Components/Scenes/TracksScene'
import OriginalsTrack from './Models/OriginalsTrack'
import CollectionDeck from './Models/CollectionDeck'
import CollectionShelf from './Models/CollectionShelf'
import { AmuletTracker } from './Models/Amulet'
import { PACK_COST, CARDS_IN_PACK, AMULET_ASSEMBLY_REWARD, TESTING_NEW_EXPERIENCE } from './Data/settings'
import QuickLook from './Components/GUI/QuickLook'
import CollectionTrackManager from './Models/CollectionTrackManager'
import { TRACKS } from './Data/tracks'

var collectionDeck
const originalsTracks = OriginalsTrack.loadAll()

if (TESTING_NEW_EXPERIENCE) {
	collectionDeck = new CollectionDeck([], 500)
	const startingCards = []
	for (let i = 0; i < 10; i ++) {
		startingCards.push(originalsTracks[0].pickRandomCard(collectionDeck))
		startingCards.push(originalsTracks[1].pickRandomCard(collectionDeck))
		startingCards.push(originalsTracks[2].pickRandomCard(collectionDeck))
		startingCards.push(originalsTracks[3].pickRandomCard(collectionDeck))
		startingCards.push(originalsTracks[4].pickRandomCard(collectionDeck))
		startingCards.push(originalsTracks[5].pickRandomCard(collectionDeck))
	}
	collectionDeck.collectCards(startingCards)
} else {
	collectionDeck = CollectionDeck.load()
}

const collectionTracks = CollectionTrackManager.load(originalsTracks, collectionDeck)
// const collectionTracks = originalsTracks.map(originalsTrack => new CollectionTrack(originalsTrack, collectionDeck))

// const amuletTracker = new AmuletTracker([new Amulet('0')])
// amuletTracker.save()
const amuletTracker = AmuletTracker.load()

const shelf = CollectionShelf.load()

// [TODO]
// [X] Progress bar on track overview.
// [X] Amulet assets.
// [X] Full amulet game loop.
// [X] Explain buy pack cost & assemble amulet prize coin.
// [X] Nice coin add animation.
// [X] Load/Save.
// [X] Prevent dupes amulets.
// [X] Reduce chance of dupes on cards.
// [X] Show number of card on Open Pack scene.
// [X] Compress track width by replacing several empty cards with ··· between them.
// [X] Get reward for duplicate card when new & dupe are obtained on the same pull.
// [X] Highly prioritize getting cards in correct order with minimum leeway.
// [X] Display new cards in # order.
// [X] Split backdrop and cover art for tracks.
// [X] Intelligently reduce dupes by giving other, new cards in some cases.
// [X] FIX: Collection cards not saving, currency does. Solution: Bad uniques method.
// [X] Quick Look with sequence when clicking a card.
// [X] Small size variations of pictures for optimization.
// [X] Drag track cards to favorites like Top Shelf but for track preview
// [X] Quick look favorites from a track.
// [X] Allow to remove card from a shelf.

// OPTIONAL
// [ ] Folders to make own collections.
// [ ] Customize cover art for tracks.
// [ ] Slideshow to play manually on a track, globally, or automatically when AFK.

// [TODO] For a release only
// [X] Order tracks randomly initially.
// [X] Cipher/decipher savestates.
// [X] Encrypt filenames for cards.
// [X] Encrypt filenames for amulets.
// [X] Preload images before entering a scene.
// [-] First time experience.
// [X] Encrypt unlocked amulets.
// [X] Icomoon download.

function App() {
	const [hideHeader, setHideHeader] = useState(false)
	const [currency, _setCurrency] = useState(collectionDeck.currency)
	const [newlyObtainedCardsFilenames, setNewlyObtainedCardsFilenames] = useState([])
	const [openPackCards, setOpenPackCards] = useState([])
	const [scene, setScene] = useState()
	const [activeTrack, setActiveTrack] = useState() // [CollectionTrack]
	const [assemblingAmulet, setAssemblingAmulet] = useState()
	const [transitioning, setTransitioning] = useState()
	const [transitioningTo, setTransitioningTo] = useState()
	const [lockout, setLockout] = useState(false)
	const [shelfState, setShelfState] = useState(shelf)
	const [collectionTracksState, setCollectionTracksState] = useState(collectionTracks.getTracks())
	const [trackShelfState, setTrackShelfState] = useState()
	const [dropzoneVisible, setDropzoneVisible] = useState(false)
	const [showTrackShelf, setShowTrackShelf] = useState(false)
	const [quickLookStartingCard, setQuickLookStartingCard] = useState()
	const [quickLookCards, setQuickLookCards] = useState([])
	const [quickLookVisible, setQuickLookVisible] = useState(false)
	const [draggedCard, setDraggedCard] = useState()
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })
	const [trackView, setTrackView] = useState(localStorage.getItem('trackView') || 'row')
	const [lastSeenTrackId, _setLastSeenTrackId] = useState(parseInt(localStorage.getItem('lstid')))

	const preventHeaderKeyCapture = quickLookVisible || showTrackShelf
	const lastAddedTrackId = parseInt(TRACKS[TRACKS.length - 1].id)

	const startQuickLook = (startingCard, cards) => {
		setQuickLookStartingCard(startingCard || cards[0])
		setQuickLookCards(cards)
		requestAnimationFrame(() => setQuickLookVisible(true))
	}

	const onQuickLookClose = () => {
		setQuickLookVisible(false)
		setTimeout(() => setQuickLookStartingCard(undefined), 200)
	}

	const onMouseMove = (e) => {
		setCursorPosition({ x: e.clientX, y: e.clientY })
	}

	const onKeyDown = (e) => {
		if (e.key === 'Escape' && dropzoneVisible) {
			e.preventDefault()
			onHideShelf()
		}
	}

	useEffect(() => {
		goToScene('home')
	}, [])

	useEffect(() => {
		document.addEventListener('mousemove', onMouseMove)
		document.addEventListener('keydown', onKeyDown)
		return () => {
			document.removeEventListener('mousemove', onMouseMove)
			document.removeEventListener('keydown', onKeyDown)
		}
	})

	const setCurrency = (value) => {
		collectionDeck.setCurrency(value)
		_setCurrency(collectionDeck.currency)
	}

	const setLastSeenTrackId = (trackId) => {
		localStorage.setItem('lstid', trackId)
		_setLastSeenTrackId(trackId)
	}

	const modCurrency = (mod) => {
		setCurrency(collectionDeck.currency + mod)
	}
	
	const onTrackSelect = (track) => {
		setActiveTrack(track)
		if (track.getId() === lastAddedTrackId) setLastSeenTrackId(track.getId())
		setLockout(true)
		setTimeout(() => {
			setLockout(false)
			setTrackShelfState(track.favoritesShelf)
			goToScene('track')
		}, 400)
	}
	
	const onTopShelfCardSwap = (indexFrom, indexTo) => {
		shelf.swapCards(indexFrom, indexTo)
		setShelfState(Object.assign({}, shelf))
	}
	
	const onCardDragStart = (card) => {
		setDropzoneVisible(true)
		setDraggedCard(card)
	}
	
	const onCardDragEnd = () => {
		setDraggedCard(undefined)
		if (!showTrackShelf) {
			setDropzoneVisible(false)
		}
	}
	
	const onCardDropOnTopShelf = (index) => {
		shelf.addCardAt(draggedCard, index)
		setShelfState(Object.assign({}, shelf))
	}
	
	const onTopShelfCardRemove = (index) => {
		shelf.removeCardAt(index)
		setShelfState(Object.assign({}, shelf))
	}
	
	const onCardDropOnTrackShelf = (index) => {
		activeTrack.addFavoritesCardAt(draggedCard, index)
		setTrackShelfState(Object.assign({}, activeTrack.favoritesShelf))
	}
	
	const onTrackShelfCardSwap = (indexFrom, indexTo) => {
		activeTrack.swapFavoritesCards(indexFrom, indexTo)
		setTrackShelfState(Object.assign({}, activeTrack.favoritesShelf))
	}
	
	const onTrackShelfCardRemove = (index) => {
		activeTrack.removeFavoritesCardAt(index)
		setTrackShelfState(Object.assign({}, activeTrack.favoritesShelf))
	}
	
	const onAmuletAssembled = () => {
		goToScene('amulets', () => {
			amuletTracker.collectAmulet(assemblingAmulet)
			setAssemblingAmulet(undefined)
			modCurrency(AMULET_ASSEMBLY_REWARD)
		})
	}

	const onTrackPositionShift = (track, direction) => {
		console.log('onTrackPositionShift', track, direction)
		collectionTracks.shiftTrack(track, direction)
		setCollectionTracksState(collectionTracks.getTracks().concat())
	}
	
	const goBackTargetScene = () => {
		switch (scene) {
			case 'track':
				return 'tracksOverview'
			case 'amulets':
			case 'tracksOverview':
				return 'home'
			case 'openPack':
				return 'track'
			case 'assembleAmulet':
				return 'amulets'
			default:
				return undefined
		}
	}
	
	const onGoBack = () => {
		if (scene === 'track') {
			setTrackShelfState(undefined)
			onHideShelf()
		}
		goToScene(goBackTargetScene())
	}
	
	const onGoAmulets = () => {
		goToScene('amulets')
	}
	
	const onAssembleAmulet = () => {
		goToScene('assembleAmulet', () => {
			setAssemblingAmulet(amuletTracker.pickNewRandomAmulet())
		})
	}
	
	const onShowShelf = () => {
		setShowTrackShelf(true)
		setDropzoneVisible(true)
	}
	
	const onHideShelf = () => {
		setDropzoneVisible(false)
		setTimeout(() => setShowTrackShelf(false), 250)
	}
	
	const goToScene = (scene, onMidTransition) => {
		setTransitioning(true)
		setTransitioningTo(scene)
		setTimeout(() => {
			setTransitioning(false)
			setTransitioningTo(null)
			if (onMidTransition) {
				onMidTransition()
			}
			setScene('')
			requestAnimationFrame(() => setScene(scene))
		}, 800)
	}
	
	const onBuyPack = () => {
		if (currency >= PACK_COST) {
			modCurrency(-PACK_COST)
			setLockout(true)
			setTimeout(() => {
				goToScene('openPack', () => {
					setLockout(false)
					setHideHeader(true)
					const obtainedCards = activeTrack.pickRandomCards(CARDS_IN_PACK, collectionDeck)
					setOpenPackCards(obtainedCards)
					const collectedCards = collectionDeck.collectCards(obtainedCards)
					setNewlyObtainedCardsFilenames(collectedCards.map(card => card.filename))
				})
			}, 400)
		}
	}
	
	const onNewCardsSummaryShow = (moneyBack = 0) => {
		setHideHeader(false)
		requestAnimationFrame(() => modCurrency(moneyBack))
	}

	const onTrackViewChange = (newView) => {
		setTrackView(newView)
		localStorage.setItem('trackView', newView)
	}
	
	const getParallaxPosition = () => {
		const degree = 1
		const offsetX = 0.5 - cursorPosition.x / window.innerWidth
		const offsetY = 0.5 - cursorPosition.y / window.innerHeight
		return `translateX(${offsetX * degree}%) translateY(${offsetY * degree}%)`
	}

	return <div className={`App ${lockout && 'lockout'}`}>
		<div className={`backdrop ${scene !== 'home' && 'darker'} ${transitioningTo === 'home' && 'bright'}`} style={{ backgroundImage: 'url("splashes/1.jpg")', transform: getParallaxPosition() }}></div>
		{/* { draggedCard && <div className="dragged-card" style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px`, backgroundImage: `url('${draggedCard.filename}')` }}></div>} */}
		{ quickLookStartingCard && quickLookCards && <QuickLook
			startingViewedCard={quickLookStartingCard}
			cards={quickLookCards}
			onClose={onQuickLookClose}
			visible={quickLookVisible}
			cursorPosition={cursorPosition}
		/> }
		<DropzonesOverlay
			topShelf={!showTrackShelf && shelfState}
			bottomShelf={trackShelfState}
			visible={dropzoneVisible}
			onCardDropOnTopShelf={onCardDropOnTopShelf}
			onCardDropOnTrackShelf={onCardDropOnTrackShelf}
			onTrackShelfCardSwap={onTrackShelfCardSwap}
			onStartQuickLook={startQuickLook}
			onTrackShelfCardRemove={onTrackShelfCardRemove}
		/>
		<Header
			preventKeyCapture={preventHeaderKeyCapture}
			onGoBack={goBackTargetScene() && onGoBack}
			onBuyPack={(scene === 'track' || scene === 'openPack') && onBuyPack}
			buyPackNotif={scene === 'track' && activeTrack.isEmpty()}
			// onGoAmulets={scene === 'tracksOverview' && onGoAmulets}
			// goAmuletsNotif={scene === 'tracksOverview' && currency < PACK_COST * 5}
			onAssembleAmulet={scene === 'amulets' && onAssembleAmulet}
			onShowShelf={scene === 'track' && !activeTrack.isEmpty() && !dropzoneVisible && onShowShelf}
			onHideShelf={scene === 'track' && !activeTrack.isEmpty() && dropzoneVisible && onHideShelf}
			currency={currency}
			hidden={hideHeader}
			progressValue={(scene === 'track' || scene === 'openPack') && !activeTrack.isEmpty() ? activeTrack.totalCollected() : undefined}
			progressMax={(scene === 'track' || scene === 'openPack') && !activeTrack.isEmpty() ? activeTrack.totalToCollect() : undefined}
			trackView={scene === 'track' && trackView}
			onTrackViewChange={onTrackViewChange}
		/>
		{ scene === 'home' &&
			<HomeScene
				onSceneSelect={scene => goToScene(scene)}
			/>
		}
		{ scene === 'tracksOverview' &&
			<TracksScene
				collectionTracks={collectionTracksState}
				onSelect={track => onTrackSelect(track)}
				shelf={shelf}
				onTopShelfCardSwap={onTopShelfCardSwap}
				onTopShelfCardRemove={onTopShelfCardRemove}
				onStartQuickLook={startQuickLook}
				onTrackPositionShift={onTrackPositionShift}
				newTrackId={lastSeenTrackId >= 0 && lastAddedTrackId !== lastSeenTrackId ? lastAddedTrackId : undefined}
			/>
		}
		{ scene === 'track' &&
			<Track
				collectionTrack={activeTrack}
				newlyObtainedCardsFilenames={newlyObtainedCardsFilenames}
				onCardDragStart={onCardDragStart}
				onCardDragEnd={onCardDragEnd}
				onStartQuickLook={startQuickLook}
				view={trackView}
			/>
		}
		{ scene === 'amulets' &&
			<AmuletsScene amuletTracker={amuletTracker} />
		}
		{ scene === 'assembleAmulet' &&
			<AmuletAssembly amulet={assemblingAmulet} onAmuletAssembled={onAmuletAssembled} />
		}
		{ scene === 'openPack' && <OpenPackScene
			track={activeTrack}
			cards={openPackCards}
			newCardsFilenames={newlyObtainedCardsFilenames}
			onSummaryShow={onNewCardsSummaryShow}
			onCardDragStart={onCardDragStart}
			onCardDragEnd={onCardDragEnd}
			onStartQuickLook={startQuickLook}
		/> }
		<div className={`transition-veil ${transitioning && 'active'}`}></div>
	</div>
}

export default App
