import React from 'react'
import './OpenPackScene.scss'
import Card from '../Card'
import { DEBUG_SHORTCUTS, DUPE_CARD_REWARD } from '../../Data/settings'
import Deck from '../../Models/Deck'
import { preloadImages } from '../../Utils/images'

export default class OpenPackScene extends React.PureComponent {
	constructor (props) {
		super(props)
		this.state = {
			spotlightIndex: -1,
			summaryView: false,
			preFirstCard: true,
			showSpotlightCardId: false
		}
		this.onKeyDown = this.onKeyDown.bind(this)
	}
	
	async componentDidMount () {
		requestAnimationFrame(() => this.setState({ preFirstCard: false }))
		document.addEventListener('keydown', this.onKeyDown)
		
		const preloadFilenames = []
		this.props.cards.forEach(card => {
			if (!preloadFilenames.includes(card.getFilename())) {
				preloadFilenames.push(card.getFilename())
			}
		})
		preloadImages(preloadFilenames).finally(() => this.showNextCard())
	}

	componentWillUnmount () {
		document.removeEventListener('keydown', this.onKeyDown)
	}
	
	showNextCard () {
		if (this.state.spotlightIndex === this.lastIndex()) {
			this.showSummary()
			return
		}
		const nextIndex = this.state.spotlightIndex + 1
		this.setState({
			spotlightIndex: nextIndex,
			showSpotlightCardId: true
		})
		const nextCard = this.props.cards[nextIndex]
		const isSpotlightNew = this.isCardNew(nextCard, nextIndex)
		const isSpotlightNewbie = nextCard.id <= 5
		const cardDuration = (isSpotlightNew
			? (isSpotlightNewbie ? 6 : 7)
			: 4
		) * 1000
		this.setState({ animationDuration: cardDuration })
		setTimeout(() => this.showNextCard(), cardDuration + 500)
		setTimeout(() => this.setState({ showSpotlightCardId: false }), cardDuration * 0.65)
	}
	
	showSummary () {
		this.setState({ summaryView: true })
		this.props.onSummaryShow(this.getTotalDupes() * DUPE_CARD_REWARD)
	}
	
	
	
	lastIndex () {
		return this.props.cards.length - 1
	}
	
	currentCard () {
		return this.props.cards[this.state.spotlightIndex]
	}

	getTotalDupes () {
		let dupesTotal = 0
		this.props.cards.forEach((card, index) => {
			if (!this.isCardNew(card, index)) {
				dupesTotal += 1
			}
		})
		return dupesTotal
	}
	
	isCardNew (card, packIndex) {
		const { cards, newCardsFilenames } = this.props
		if (!newCardsFilenames || !card) {
			return false
		}
		// Check if dupe vs existing collection pre-pull.
		if (!newCardsFilenames.includes(card.filename)) {
			return false
		}
		// Check if dupe within this new pack.
		for (let i = 0; i < packIndex; i ++) {
			if (cards[i].is(card)) {
				return false
			}
		}
		return true
	}

	getUniqueCards () {
		const deck = new Deck(this.props.cards)
		return deck.uniqueCards()
	}

	onKeyDown (e) {
		if (DEBUG_SHORTCUTS && e.key === 'Enter') {
			this.showSummary()
		}
	}
	
	render () {
		const { cards, track, newCardsFilenames, onCardDragStart, onCardDragEnd, onStartQuickLook } = this.props
		const { summaryView, preFirstCard, spotlightIndex, showSpotlightCardId, animationDuration } = this.state
		const isSpotlightNew = this.isCardNew(this.currentCard(), this.state.spotlightIndex)
		const showNewCardBackdrop = isSpotlightNew || (this.state.summaryView && newCardsFilenames && newCardsFilenames.length > 0)

		const classNames = ['OpenPackScene']
		classNames.push(summaryView ? 'summary-view' : 'spotlight-view')
		if (preFirstCard) classNames.push('pre-first-card')

		const visibleSpotlightCards = cards.filter((_card, i) => i <= spotlightIndex)
		const latestSpotlightCard = visibleSpotlightCards[visibleSpotlightCards.length - 1]
		
		return <div className={classNames.join(' ')}>
			<div className={`new-card-backdrop ${showNewCardBackdrop && 'show'}`}></div>
			<div className="backdrop" style={{ backgroundImage: `url('${track.getBackdrop()}')` }}></div>
			<div className="summary">
				<div className="scrollable">
					{ cards.map((card, i) => <Card
							key={i}
							filename={card.filename}
							draggable={true}
							onDragStart={onCardDragStart && (() => onCardDragStart(card))}
							onDragEnd={onCardDragEnd}
							onClick={onStartQuickLook ? () => onStartQuickLook(card, this.getUniqueCards()) : undefined}
						>
							<div className="id">{ card.id + 1 }</div>
							{ !this.isCardNew(card, i) && <div className="coin-reward">
								<div className="coin">
									<div className="icon icon-currency"></div>
									<div className="value">+{ DUPE_CARD_REWARD }</div>
								</div>
							</div> }
						</Card>
					) }
				</div>
			</div>
			<div
				className={`spotlight ${isSpotlightNew ? 'new' : 'dupe'} ${showSpotlightCardId && 'show-card-id'}`}
				style={{ '--animation-duration': `${animationDuration}ms` }}
			>
				{ latestSpotlightCard !== undefined && <div className="current-card-id">{ latestSpotlightCard.id + 1 }</div> }
				{ visibleSpotlightCards.map((card, i) =>
					<Card key={i} filename={card.filename} />
				) }
			</div>
		</div>
	}
}