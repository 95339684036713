import { useState } from 'react'
import './Track.scss'
import Card from './Card'

export default function Track ({ collectionTrack, minimized, view, showAsNew, onSelect, newlyObtainedCardsFilenames, favoritesShelf, onCardDragStart, onCardDragEnd, onStartQuickLook, onPositionShift, animatingShiftUp, animatingShiftDown, onPreviewFavorites }) {
	const [active, setActive] = useState()
	
	const onClick = () => {
		setActive(true)
		if (onSelect) onSelect()
	}

	const completionRate = collectionTrack.completionRate()
	
	const classNames = ['Track']
	if (minimized) classNames.push('minimized')
	if (active) classNames.push('active')
	if (animatingShiftUp) classNames.push('shifting-up')
	if (animatingShiftDown) classNames.push('shifting-down')
	if (showAsNew) classNames.push('new')
	if (collectionTrack.isEmpty()) classNames.push('empty')
	
	let cards
	if (minimized) {
		const favCards = favoritesShelf.getCards()
		const collectionCards = collectionTrack.collectionCards()
			.filter(card => !favoritesShelf.hasCard(card))
			.slice(0, Math.max(0, 11 - favCards.length))
		cards = favCards.concat(favCards.length > 0 && collectionCards.length > 0 ? ['favseparator'] : [], collectionCards)
	} else {
		cards = collectionTrack.getOriginalsAsCards()
		let newCards = []
		let buffer = []
		cards.forEach((card, i) => {
			if (collectionTrack.hasCard(card) || i === cards.length - 1) {
				if (buffer.length > 3 && view === 'row') {
					newCards.push(buffer[0])
					newCards.push('separator')
					newCards.push(buffer[buffer.length - 1])
				} else {
					newCards = newCards.concat(buffer)
				}
				buffer = []
				newCards.push(card)
			} else {
				buffer.push(card)
			}
		})
		cards = newCards
	}
	
	return <div className={classNames.join(' ')}>
		<div className={`backdrop ${collectionTrack.getBackdropPosition()}`} style={{ backgroundImage: `url('${minimized ? collectionTrack.getCoverArt() : collectionTrack.getBackdrop()}')` }} onClick={onClick}></div>
		{ onPositionShift && <div className="position-shift">
			<button onClick={() => onPositionShift(collectionTrack, -1)} className="quick-action-button icon icon-arrow-medium-up"></button>
			<button onClick={() => onPositionShift(collectionTrack, 1)} className="quick-action-button icon icon-arrow-medium-down"></button>
		</div> }
		{ onPreviewFavorites && <button onClick={onPreviewFavorites} className="quick-action-button preview-favs icon icon-preview"></button> }
		{ completionRate > 0 && completionRate < 1 && <div className="progress-bar">
			<div className="progress" style={{ width: `${completionRate * 100}%` }}></div>
		</div> }
		<div className="open-cta">Open this collection track →</div>
		<div className={`scrollable ${view || 'row'}`}>
			{
				cards.map((card, i) => {
					if (card === 'separator') {
						return <div key={`separator-${i}`} className="many-cards-separator">···</div>
					} else if (card === 'favseparator') {
						return <div key={`favseparator-${i}`} className="favorites-separator"></div>
					} else {
						let filenameProp = collectionTrack.hasCard(card) ? (minimized ? card.getSmallFilename() : card.getFilename()) : undefined
						const isNew = newlyObtainedCardsFilenames && newlyObtainedCardsFilenames.includes(card.getFilename())
						return <Card
							key={`${i}-${card.getTrackId()}-${card.id}`}
							id={card.id}
							filename={filenameProp}
							isNew={isNew}
							draggable={true}
							onDragStart={onCardDragStart && (() => onCardDragStart(card))}
							onDragEnd={onCardDragEnd}
							onClick={onStartQuickLook ? () => onStartQuickLook(card, collectionTrack.collectionCards()) : undefined}
						/>
					}
				})
			}
		</div>
	</div>
}