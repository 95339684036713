import "./QuickLook.scss"
import Card from "../Card"
import { useEffect, useState } from "react"

export default function QuickLook ({ startingViewedCard, cards, visible, onCardChange, onClose, cursorPosition }) {
	const [ viewedCard, setViewedCard ] = useState(startingViewedCard)
	const classNames = ['QuickLook']
	if (visible) classNames.push('visible')

	const onKeyDown = (e) => {
		if (!viewedCard) {
			return
		}
		e.preventDefault()
		if (e.key === 'Escape') {
			if (onClose) onClose()
		}
		const viewedIndex = cards.length > 0 ? cards.findIndex(card => card.is(viewedCard)) : -1
		if (e.key === 'ArrowLeft') {
			const targetCard = cards[viewedIndex <= 0 ? cards.length - 1 : viewedIndex - 1]
			setViewedCard(targetCard)
			if (onCardChange) onCardChange(targetCard)
		} else if (e.key === 'ArrowRight') {
			const targetCard = cards[viewedIndex >= cards.length - 1 ? 0 : viewedIndex + 1]
			setViewedCard(targetCard)
			if (onCardChange) onCardChange(targetCard)
		}
	}

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown)
		return () => {
			document.removeEventListener('keydown', onKeyDown)
		}
	})
	
	return <div className={classNames.join(' ')} onClick={onClose}>
		<div className="container" style={{ transformOrigin: `${cursorPosition.x}px ${cursorPosition.y}px` }}>
			{ viewedCard && <Card
				filename={viewedCard.filename}
			/> }
		</div>
	</div>
}