export function timesArray (n) {
	const indeces = []
	for (let i = 0; i < n; i ++) {
		indeces.push(i)
	}
	return indeces
}

export function shuffledArray (array) {
	array = array.concat()
	let currentIndex = array.length, randomIndex

	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex--

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]]
	}

	return array
}