import "./DropzonesOverlay.scss"
import TopShelf from "../TopShelf"

export default function DropzonesOverlay ({ visible, topShelf, bottomShelf, onCardDropOnTopShelf, onCardDropOnTrackShelf, onTrackShelfCardSwap, onStartQuickLook, onTrackShelfCardRemove }) {
	const classNames = ['DropzonesOverlay']
	if (visible) classNames.push('visible')
	
	return <div className={classNames.join(' ')}>
		{ topShelf
			? <TopShelf
				title="Top Shelf"
				cardsWithPositions={topShelf.cards}
				onCardDrop={onCardDropOnTopShelf}
			/>
			: <div></div>
		}
		{ bottomShelf && <TopShelf
			title="Track Favorites"
			cardsWithPositions={bottomShelf.cards}
			titleAtBottom={true}
			onCardDrop={onCardDropOnTrackShelf}
			onCardSwap={onTrackShelfCardSwap}
			rearrangeable={true}
			onStartQuickLook={onStartQuickLook}
			onCardRemove={onTrackShelfCardRemove}
		/> }
	</div>
}