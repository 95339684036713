import React from 'react'
import './Card.scss'
import { preloadImage } from '../Utils/images'

const maxTiltX = 16
const maxTiltY = 6

export default class Card extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			tiltX: 0,
			tiltY: 0,
			tiltAmp: 0,
			dropTarget: false,
			showRemove: false,
			loaded: !props.filename
		}
		this.touching = false
		this.tilting = false
		this.elementRef = React.createRef()
		this.pictureRef = React.createRef()
	}
	
	async componentDidMount () {
		if (!this.state.loaded) {
			preloadImage(this.props.filename).finally(() => this.setState({ loaded: true }))
		}
	}
	
	
	
	/*** Tilting ***/
	
	allowHover () {
		return !!this.props.filename
	}
	
	updateTiltAmp () {
		const { tiltAmp } = this.state
		if (this.tilting && tiltAmp < 1) {
			this.setState({ tiltAmp: Math.min(1, tiltAmp + 0.005 + (0.1 - tiltAmp * 0.1)) })
			requestAnimationFrame(() => this.updateTiltAmp())
		} else if (!this.tilting && tiltAmp > 0) {
			this.setState({ tiltAmp: Math.max(0, tiltAmp - (0.01 + tiltAmp * 0.1))  })
			requestAnimationFrame(() => this.updateTiltAmp())
		}
	}
	
	startTilting () {
		clearTimeout(this.showRemoveTimeout)
		this.showRemoveTimeout = setTimeout(() => this.setState({ showRemove: true }), 750)
		if (!this.allowHover() || this.props.disableTilting) {
			return
		}
		this.tilting = true
		this.updateTiltAmp()
	}

	tiltInteraction (target, clientX, clientY) {
		if (!this.allowHover()) {
			return
		}
		const rect = target.getBoundingClientRect()
		const centerAlphaX = ((clientX - rect.x) / rect.width - 0.5) * 2
		const centerAlphaY = ((clientY - rect.y) / rect.height - 0.5) * 2
		this.setState({
			tiltX: centerAlphaX,
			tiltY: -centerAlphaY
		})
		if (Math.abs(centerAlphaX) > 1.1 || Math.abs(centerAlphaY) > 1.1) {
			this.onTouchEnd()
		}
	}

	onMouseMove (e) {
		if (e.nativeEvent && e.nativeEvent.webkitForce) {
			return
		}
		this.tiltInteraction(e.target, e.clientX, e.clientY)
	}

	onMouseLeave () {
		this.tilting = false
		this.touching = false
		this.updateTiltAmp()
		clearTimeout(this.showRemoveTimeout)
		this.setState({ showRemove: false })
	}

	onTouchMove (e) {
		e.preventDefault()
		e.stopPropagation()
		this.touching = true
		this.tiltInteraction(e.target, e.touches[0].clientX, e.touches[0].clientY)
	}

	onTouchEnd () {
		if (this.touching) {
			this.onMouseLeave()
		}
	}
	
	onDragStart (e) {
		if (!this.isDraggable()) return
		e.dataTransfer.setDragImage(this.pictureRef.current, 10, 10)
		this.props.onDragStart({
			id: this.props.id,
			filename: this.props.filename
		})
	}
	
	onDragEnd (e) {
		if (!this.props.onDragEnd) return
		this.props.onDragEnd({
			id: this.props.id,
			filename: this.props.filename
		})
	}
	
	onDrop (e) {
		if (!this.props.onDrop) return
		this.setState({ dropTarget: false })
		this.props.onDrop({
			id: this.props.id,
			filename: this.props.filename
		})
	}
	
	onDragEnter () {
		if (!this.props.dropzone) return
		this.setState({ dropTarget: true })
	}
	
	onDragLeave () {
		if (!this.props.dropzone) return
		this.setState({ dropTarget: false })
	}

	isDraggable () {
		return this.props.draggable && this.props.onDragStart && !!this.props.filename
	}
	
	
	
	/*** Render ***/
	
	render () {
		const { tiltAmp, tiltX, tiltY, dropTarget, showRemove, loaded } = this.state
		const { children, id, filename, disabled, isNew, onClick, onRemove } = this.props
		const draggable = this.isDraggable()
		const clickable = onClick && !!filename
		const blank = !filename
		
		const classNames = ['Card']
		if (blank) classNames.push('blank')
		if (tiltAmp > 0) classNames.push('tilting')
		if (isNew) classNames.push('new')
		if (dropTarget) classNames.push('drop-target')
		if (draggable) classNames.push('draggable')
		if (clickable) classNames.push('clickable')
		if (loaded) classNames.push('loaded')
	
		const tiltRotateMod = disabled ? 0.4 : 0.8
		const parallaxTiltMod = tiltRotateMod * 0.8
		const finalTiltX = tiltAmp * tiltX * parallaxTiltMod
		const finalTiltY = tiltAmp * tiltY * parallaxTiltMod
		const transformScale = 1 + (!disabled ? 0.15 : 0) * tiltAmp
		const transformRotateY = finalTiltX * maxTiltX * tiltRotateMod
		const transformRotateX = finalTiltY * maxTiltY * tiltRotateMod
		
		return <div
			className={classNames.join(' ')}
			draggable={draggable}
			onDragStart={this.onDragStart.bind(this)}
			onDragEnd={this.onDragEnd.bind(this)}
			onDragEnter={this.onDragEnter.bind(this)}
			onDragLeave={this.onDragLeave.bind(this)}
			onDrop={this.onDrop.bind(this)}
			onDragOver={e => e.preventDefault()}
			ref={this.elementRef}
		>
			<div
				className="pointer-surface"
				onMouseEnter={this.startTilting.bind(this)}
				onMouseMove={this.onMouseMove.bind(this)}
				onTouchMove={this.onTouchMove.bind(this)}
				onMouseLeave={this.onMouseLeave.bind(this)}
				onTouchEnd={this.onTouchEnd.bind(this)}
			>
				<div
					className="container"
					onClick={clickable ? onClick : undefined}
					style={{
						transform: `scale(${transformScale}) rotateY(${transformRotateY}deg) rotateX(${transformRotateX}deg)`,
						filter: `brightness(${1 + (finalTiltY) / 25})`
					}}
					 ref={this.pictureRef}
				>
					{ !blank && <div className="picture" style={{ backgroundImage: `url("${filename}")`, backgroundPosition: `${50 + finalTiltX * 30}% ${50 - finalTiltY * 10}%`}}></div> }
					{ blank && id !== undefined && <div className="id">{ id + 1 }</div> }
					{ children && <div className="children">{ children }</div> }
				</div>
			</div>
			{ onRemove && <button className={`remove icon icon-clear ${showRemove && 'visible'}`} onClick={onRemove} /> }
		</div>
	}
}