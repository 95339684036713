import { useState } from "react"
import "./TopShelf.scss"
import Card from "./Card"

export default function TopShelf ({ title, titleAtBottom, cardsWithPositions, onCardDrop, onCardSwap, rearrangeable, onStartQuickLook, onCardRemove }) {
	let [dragStartIndex, setDragStartIndex] = useState(undefined)

	const onSwap = (dragEndIndex) => {
		if (!onCardSwap) return
		onCardSwap(dragStartIndex, dragEndIndex)
		setDragStartIndex(undefined)
	}

	const classNames = ['TopShelf']
	if (titleAtBottom) classNames.push('reverse-flow')

	return <div className={ classNames.join(' ') }>
		<div className="title">{ title || 'Top Shelf' }</div>
		<div className="scrollable">
			<div className="space"></div>
			{ Object.keys(cardsWithPositions)
				.map(index => {
					index = parseInt(index)
					const card = cardsWithPositions[index] || {}
					return <Card
						key={`${index}-${card.id}`}
						id={card.id}
						filename={card.getSmallFilename && card.getSmallFilename()}
						draggable={rearrangeable}
						onRemove={rearrangeable && onCardRemove && (() => onCardRemove(index))}
						dropzone={true}
						onDragStart={() => setDragStartIndex(index)}
						onDrop={() => dragStartIndex !== undefined && rearrangeable ? onSwap(index) : onCardDrop(index) }
						onClick={() => onStartQuickLook(card, Object.values(cardsWithPositions).filter(card => card))}
					/>
				})
			}
			<div className="space"></div>
		</div>
	</div>
}