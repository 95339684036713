window.preloadedImages = []

export function preloadImage (url) {
	return new Promise((resolve, reject) => {
		if (window.preloadedImages.includes(url)) {
			resolve(url)
			return
		}
		let path = new URL(url, window.location.href).href
		let img = new Image()
		img.onload = () => {
			resolve(url)
			window.preloadedImages.push(url)
		}
		img.onerror = error => {  reject(error) }
		img.src = path
	})
}

export function preloadImages (urls) {
	return Promise.all(urls.map(url => preloadImage(url)))
}