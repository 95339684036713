import React, { useRef, useState } from 'react'
import Track from '../Track'
import TopShelf from '../TopShelf'
import './TracksScene.scss'

export default function TracksScene ({ collectionTracks, onSelect, onTrackPositionShift, shelf, onTopShelfCardSwap, onTopShelfCardRemove, onStartQuickLook, newTrackId }) {
	const [animatingUpIndex, setAnimatingUpIndex] = useState(-1)
	const [animatingDownIndex, setAnimatingDownIndex] = useState(-1)
	const elementRef = useRef()

	const _onTrackPositionShift = (index, track, direction) => {
		if (direction < 0) {
			if (index <= 0) return
			setAnimatingUpIndex(index)
			setAnimatingDownIndex(index - 1)
		} else if (direction > 0) {
			if (index >= collectionTracks.length - 1) return
			setAnimatingUpIndex(index + 1)
			setAnimatingDownIndex(index)
		}
		setTimeout(() => {
			onTrackPositionShift(track, direction)
			setAnimatingUpIndex(-1)
			setAnimatingDownIndex(-1)
		}, 600)
	}

	const scrollToEnd = () => {
		elementRef.current.scrollTo({
			top: elementRef.current.scrollHeight,
			left: 0,
			behavior: 'smooth'
		})
	}

	return <div className="TracksScene" ref={elementRef}>
		<div className="padding"></div>
		{ shelf.hasCards() && <TopShelf
			cardsWithPositions={shelf.cards}
			onCardSwap={onTopShelfCardSwap}
			onCardRemove={onTopShelfCardRemove}
			rearrangeable={true}
			onStartQuickLook={onStartQuickLook}
		/> }
		{ newTrackId !== undefined && <div className="new-track-notif" onClick={scrollToEnd}>
			<div className="indicator"></div>
			<div className="label">New Track Below</div>
		</div> }
		{
			collectionTracks.map((track, index) => {
				const favoritesShelfCards = track.favoritesShelf.getCards()
				return <Track
					key={track.getId()}
					collectionTrack={track}
					collectionFilenames={track.getOriginalsFiles()}
					minimized={true}
					favoritesShelf={track.favoritesShelf}
					onSelect={() => onSelect(track)}
					onPositionShift={(track, direction) => _onTrackPositionShift(index, track, direction)}
					animatingShiftUp={animatingUpIndex === index}
					animatingShiftDown={animatingDownIndex === index}
					onPreviewFavorites={favoritesShelfCards.length > 0 && (() => onStartQuickLook(null, favoritesShelfCards))}
					showAsNew={newTrackId === track.getId()}
				/>
			})
		}
		<div className="padding"></div>
	</div>
}