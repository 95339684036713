import { shuffledArray } from "../Utils/array"
import CollectionTrack from "./CollectionTrack"

export default class CollectionTrackManager {
	constructor (tracks) {
		this.tracks = tracks
	}

	getTracks () {
		return this.tracks
	}

	getTrackById (id) {
		return this.tracks.find(track => track.getId() === id)
	}

	getTrackIndex (track) {
		return this.tracks.indexOf(track)
	}

	shiftTrack (track, direction) {
		const index = this.getTrackIndex(track)
		if (index < 0) {
			console.error('Track not found')
			return
		}
		const newIndex = Math.max(0, Math.min(this.tracks.length - 1, index + direction))
		if (index !== newIndex) {
			this.tracks.splice(index, 1)
			this.tracks.splice(newIndex, 0, track)
			this.save()
		}
	}

	static load (originalsTracks, collectionDeck) {
		let firstTime = false
		const orderString = localStorage.getItem('cto')
		// Get saved order.
		const order = orderString
			? orderString.split('|').map(number => parseInt(number))
			: []
		let orderedTracks
		if (order.length === 0) {
			// First timer. Fetch all and scramble them.
			firstTime = true
			orderedTracks = shuffledArray(originalsTracks)
				.map(originalsTrack => new CollectionTrack(originalsTrack, collectionDeck))
		} else {
			// Add unordered/new tracks at the end.
			originalsTracks
				.map(originalsTrack => originalsTrack.getId())
				.forEach(trackId => !order.includes(trackId) && order.push(trackId))
			// Get the correct order and return.
			const tracks = originalsTracks.map(originalsTrack => new CollectionTrack(originalsTrack, collectionDeck))
			orderedTracks = order.map(orderId => tracks.find((collectionTrack => orderId === collectionTrack.getId())))
		}
		const manager = new CollectionTrackManager(orderedTracks)
		if (firstTime) manager.save()
		return manager
	}

	save () {
		const orderString = this.tracks
			.map(collectionTrack => collectionTrack.getId())
			.join('|')
		localStorage.setItem('cto', orderString)
	}
}