import { AMULETS } from '../Data/amulets'
import { TESTING_NEW_EXPERIENCE, SAVE_DURING_TESTING_NEW_EXPERIENCE, ALLOW_SAVE } from '../Data/settings'
import { rand } from '../Utils/math'
import CryptoJS from 'crypto-js'

export class Amulet {
	constructor (id) {
		this.id = parseInt(id)
	}

	getId () {
		return this.id
	}
	
	getEncId () {
		return 10000 + 647 * (this.getId() + 5)
	}
	
	getFilename () {
		return `/amulets/${this.getEncId()}.jpg`
	}
}

export class AmuletTracker {
	constructor (collectedAmulets) {
		this.collectedAmulets = collectedAmulets
	}
	
	getFilenames () {
		return this.collectedAmulets.map(amulet => amulet.getFilename())
	}
	
	newestFirst () {
		const reversed = []
		this.collectedAmulets.forEach(amulet => reversed.unshift(amulet))
		return reversed
	}

	hasAmulet (amulet) {
		return this.collectedAmulets.some(a => a.getId() === amulet.getId())
	}

	hasAmuletById (id) {
		return this.collectedAmulets.some(a => a.getId() === id)
	}

	collectAmulet (amulet) {
		if (this.hasAmulet(amulet)) {
			return
		}
		this.collectedAmulets.push(amulet)
		this.save()
	}
	
	
	
	/*** Amulet picking ***/
	
	/**
	 * Uniques unless all of them have been collected.
	 */
	pickNewRandomAmulet () {
		const uncollectedIndeces = []
		for (let i = 0; i < AMULETS.total; i ++) {
			if (!this.hasAmuletById(i)) {
				uncollectedIndeces.push(i)
			}
		}
		let id
		if (uncollectedIndeces.length > 0) {
			id = uncollectedIndeces[rand(0, uncollectedIndeces.length - 1)]
		} else {
			id = this.pickRandomIndex()
		}
		return new Amulet(id)
	}
	
	pickRandomIndex () {
		return rand(0, AMULETS.total - 1)
	}



	/*** I/O ***/

	save () {
		if (TESTING_NEW_EXPERIENCE && !SAVE_DURING_TESTING_NEW_EXPERIENCE) return
		if (!ALLOW_SAVE) return
		// localStorage.setItem('a', JSON.stringify(this.toDict()))
		localStorage.setItem('a-enc', CryptoJS.AES.encrypt(JSON.stringify(this.toDict()), 'uc2uc2uc2uc2').toString())
	}
	
	static load () {
		// const data = localStorage.getItem('a')
		const encData = localStorage.getItem('a-enc')
		if (!encData) {
			return new AmuletTracker([])
		}
		const decryptedData = CryptoJS.AES.decrypt(encData, 'uc2uc2uc2uc2').toString(CryptoJS.enc.Utf8)
		return AmuletTracker.fromDict(JSON.parse(decryptedData))
	}

	toDict () {
		return {
			amuletIds: this.collectedAmulets.map(amulet => amulet.getId())
		}
	}
	
	static fromDict (dict) {
		const amulets = dict.amuletIds.map(amuletId => new Amulet(amuletId))
		return new AmuletTracker(amulets)
	}
}